import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { ethers } from "ethers";

async function getDeviceFingerprint(){
    const fpPromise = await FingerprintJS.load();
    const result = await fpPromise.get();
    // 排除一些不需要的组件,得到剩余的组件
    const newComponents = excludeComponents(result);
    console.log(newComponents);
    const extendedComponents = {
        ...newComponents,
        mySignId: {value: generateFingerprint()}
    };
    
    const fingerprintId = FingerprintJS.hashComponents(extendedComponents);
    return getBytes32FromVisitorId(fingerprintId);
}
//排除一些不需要的组件
function excludeComponents(result) {
    const excludeComponents = [
        // "applePay",
        // "architecture",
        // "audio",
        "audioBaseLatency",
        // "canvas",
        // "colorDepth",
        "colorGamut",
        "contrast",
        "cookiesEnabled",
        // "cpuClass",
        "deviceMemory",
        "domBlockers",
        "fontPreferences",
        "fonts",
        "forcedColors",
        "hardwareConcurrency",
        "hdr",
        "indexedDB",
        "invertedColors",
        "languages",
        "localStorage",
        "math",
        "monochrome",
        "openDatabase",
        // "osCpu",
        "pdfViewerEnabled",
        // "platform",
        "plugins",
        "privateClickMeasurement",
        "reducedMotion",
        "reducedTransparency",
        "screenFrame",
        "screenResolution",
        "sessionStorage",
        // "timezone",
        // "touchSupport",
        "vendor",
        "vendorFlavors",
        "webGlBasics",
        "webGlExtensions"
    ];
    const components = result.components;
    const newComponents = {};
    for (const [key, value] of Object.entries(components)) {
        if (excludeComponents.includes(key)) {
            continue;
        }
        newComponents[key] = value;
    }
    return newComponents;
}
function hashString(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash |= 0; // Convert to 32bit integer
    }
    return hash.toString(16);
}

function getScreenSize() {
    return `${screen.width}x${screen.height}`;
}

function getViewportSize() {
    return `${window.innerWidth}x${window.innerHeight}`;
}

function getFonts() {
    const fontList = [
        'Arial', 'Verdana', 'Times New Roman', 'Courier New', 'Georgia', 'Comic Sans MS', 'Trebuchet MS', 'Arial Black', 'Impact'
    ];
    const availableFonts = [];

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const text = 'abcdefghijklmnopqrstuvwxyz0123456789';

    fontList.forEach((font) => {
        context.font = `16px ${font}`;
        const width = context.measureText(text).width;
        context.font = `16px monospace`;
        if (context.measureText(text).width !== width) {
            availableFonts.push(font);
        }
    });

    return availableFonts.join(',');
}

function getWebGLRenderer() {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (gl) {
        const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        if (debugInfo) {
            return gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        }
    }
    return null;
}

function getCanvasFingerprint() {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.textBaseline = 'top';
    context.font = '14px Arial';
    context.textBaseline = 'alphabetic';
    context.fillStyle = '#f60';
    context.fillRect(125, 1, 62, 20);
    context.fillStyle = '#069';
    context.fillText('Hello, world!', 2, 15);
    context.fillStyle = 'rgba(102, 204, 0, 0.7)';
    context.fillText('Hello, world!', 4, 17);
    return canvas.toDataURL();
}

function generateFingerprint() {
    const screenSize = getScreenSize();
    const viewportSize = getViewportSize();
    const colorDepth = screen.colorDepth;
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const language = navigator.language;
    const plugins = Array.from(navigator.plugins).map(plugin => plugin.name).join(',');
    const doNotTrack = navigator.doNotTrack;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZoneOffset = new Date().getTimezoneOffset();
    const fonts = getFonts();
    const hardwareConcurrency = navigator.hardwareConcurrency;
    const deviceMemory = navigator.deviceMemory;
    const webGLRenderer = getWebGLRenderer();
    const canvasFingerprint = getCanvasFingerprint();
    const touchSupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    const fingerprint = [
        screenSize, viewportSize, colorDepth, userAgent, platform, language,
        plugins, doNotTrack, timeZone, timeZoneOffset, fonts, hardwareConcurrency,
        deviceMemory, webGLRenderer, canvasFingerprint, touchSupport
    ].join('|');

    return hashString(fingerprint);
}

function getBytes32FromVisitorId(visitorId) {
    // 将 visitorId 转换为 UTF-8 字节数组
    const visitorIdBytes = ethers.utils.toUtf8Bytes(visitorId);
    // 计算 visitorId 的哈希值
    const hash = ethers.utils.keccak256(visitorIdBytes);
    // 直接返回哈希值，不需要再次 hexlify
    return hash; // hash 本身已经是一个 32 字节的 hex 字符串
}

const signBytes = await getDeviceFingerprint();
export { signBytes };